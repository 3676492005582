import Vue from 'vue'
import App from './App.vue'
import router from './router';
import i18n from './i18n.js';
import http from './services/httpasync';
import cfg from './assets/config'
import mixin from './mixin'
import './assets/style.less'
 
//全局混入
Vue.mixin(mixin)

// 引入组件库的少量全局样式变量
import TDesign from 'tdesign-vue';
import { MessagePlugin } from 'tdesign-vue';
import 'tdesign-vue/es/style/index.css';
Vue.use(TDesign);

import { numberToCurrency } from '@/filter/NumberToCurrency'
Vue.filter('numberToCurrency', numberToCurrency)

import { IconFont } from 'tdesign-icons-vue';
Vue.component('IconFont',IconFont)
Vue.prototype.$cfg = cfg;
Vue.prototype.http = http;
Vue.prototype.messagePlugin = MessagePlugin;
Vue.prototype.clientWidth = document.documentElement.clientWidth;
Vue.config.productionTip = false;


new Vue({ 
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
