<template>
    <div class="MainAreaFooter"> 
        <div style="height: 40px;">
             
        </div>
        <t-row>
            <t-col :xs="12" :sm="4">
                <div class="Navigation">
                    <ul>
                        <li><h3>{{ $t('components_main_areaFooter.title1') }}</h3></li>
                        <li v-for="(item,index) of list1" :key="index" class="router-link">
                            <router-link class="router-link" :to="{name:item.name}"> 
                                {{ item.title }}
                            </router-link>
                        </li> 
                    </ul>
                </div>
            </t-col>
            <t-col :xs="12" :sm="4">
                <div class="Navigation">
                    <ul>
                        <li><h3>{{ $t('components_main_areaFooter.title2') }}</h3></li>
                        <li v-for="(item,index) of list2" :key="index" class="router-link">
                            <router-link class="router-link" :to="{name:item.name}"> 
                                {{ item.title }}
                            </router-link>
                        </li> 
                    </ul>   
                </div>
            </t-col>

            <t-col :xs="12" :sm="4">
                <div class="Navigation">
                    <ul>
                        <li><h3>{{ $t('components_main_areaFooter.title3') }}</h3></li>
                        <li v-for="(item,index) of list3" :key="index" class="router-link">
                            <router-link class="router-link" :to="{name:item.name}"> 
                                {{ item.title }}
                            </router-link>
                        </li> 
                    </ul>   
                </div>
            </t-col>
        </t-row>
        <div style="height: 60px;">
             
        </div>
        <ul class="LinksSection">
            <li>
                <router-link class="router-link" :to="{name:'PrivacyPolicy'}"> 
                    {{ $t('components_main_areaFooter.PrivacyPolicy') }}
                </router-link>
            </li>
            <li>
                <router-link class="router-link" :to="{name:'TermsAndConditions'}"> 
                    {{ $t('components_main_areaFooter.TermsAndConditions') }}
                </router-link>
            </li>
            <li>
                <router-link class="router-link" :to="{name:'FarfetchAccessibility'}"> 
                    {{ $t('components_main_areaFooter.FarfetchAccessibility') }}
                </router-link>
            </li> 
            <li>
                <router-link class="router-link" :to="{name:'ProtectionOfIntellectualProperty'}"> 
                    {{ $t('components_main_areaFooter.ProtectionOfIntellectualProperty') }}
                </router-link>
            </li>
            <li>
                <router-link class="router-link" :to="{name:'PersonalSensitiveInformationPolicy'}"> 
                    {{ $t('components_main_areaFooter.PersonalSensitiveInformationPolicy') }}
                </router-link>
            </li>
        </ul> 
        <div style="height: 10px;">
             
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MainAreaFooterPage',
    data(){
          return{ 
            list1 : [
                {
                    title : this.$t('components_main_areaFooter.Contact'),
                    name : 'Contact'
                },{
                    title : this.$t('components_main_areaFooter.howToShop'),
                    name : 'howToShop'
                }
            ],
            list2 : [
                {
                    title : this.$t('components_main_areaFooter.OrdersAndShipping'),
                    name : 'OrdersAndShipping'
                },{
                    title : this.$t('components_main_areaFooter.ReturnsAndRefunds'),
                    name : 'ReturnsAndRefunds'
                },
            ],
            list3 : [
                {
                    title : this.$t('components_main_areaFooter.PaymentAndPricing'),
                    name : 'PaymentAndPricing'
                },{
                    title : this.$t('components_main_areaFooter.Faqs'),
                    name : 'Faqs'
                }
            ]
          }
    },
    props: { 
    },
    components:{
    }
  }
  </script>
  
  <style scoped> 
    .MainAreaFooter{
        background-color: #e5e5e5;
    }
    li{
        list-style-type: none; /* 去掉圆点 */
    }
    .LinksSection{
        display: flex; /* 使用Flex布局使列表项横向显示 */
        flex-wrap: wrap; /* 设置换行 */
        padding-bottom: 10px;
        font-size: 10px;
    }
    .LinksSection li:not(:last-child) {
        border-right: 2px solid #cbbcbc;
        padding-right: 10px;
        margin-right: 10px; 
    }
    .Navigation{
        text-align: left;
    } 
    


  @media screen and (max-width: 768px) {
    /* 手机屏幕样式 */ 
  }
   
  @media screen and (min-width: 769px) {
    /* 平板或电脑屏幕样式 */ 
  }
  </style>
  