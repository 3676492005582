<template>
     <div class="CustomerService">
      <t-popup v-model="languageVisible" placement="left" trigger="click">
          <template #content> 
            <img class="CustomerServiceIcon" src="@/assets/t.png" @click="clickCustomerService('telegram')"><br>
            <img class="CustomerServiceIcon" src="@/assets/vk.png" @click="clickCustomerService('vk')">
          </template>
          <t-button variant="text" shape="square" style="width: 20px;height: 100%;">
              <div style="writing-mode: tb-rl;font-size: 15px;padding: 10px 0;">
                {{ $t('CustomerService') }}
              </div>
          </t-button>
      </t-popup>
     </div>
</template>

<script>
export default {
  name: 'CustomerServicePage',
  data() {
    return {
      languageVisible : false
    };
  },
  props: { 
  },
  mounted(){
  },
  methods:{
    clickCustomerService(type){
      if(type == 'vk'){
        window.open('https://vk.com/luxuryhotservice', '_self');
      }else{
        window.open('https://t.me/luxuryhotService', '_self');
      }
    }
  }
};
</script>

<style lang="less" scoped>
  .CustomerService{ 
    position: fixed;
    bottom: 110px;
    right: 0;
    border-radius: 5px 0 0 5px;
    background-color: #e5e5e5;
  } 
  .CustomerServiceIcon{
    width: 32px;
    height: 32px;
    padding: 10px 0;
  }
  .customer_service_icon{
    font-size: 30px !important;
  }
  @media screen and (max-width: 768px) {
    /* 手机屏幕样式 */  
  }
   
  @media screen and (min-width: 769px) {
    /* 平板或电脑屏幕样式 */   
  }
</style>
